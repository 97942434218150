import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import { findCloseToMe } from './findCloseToMe';

export const frontpageSearch = () => {
  const findCloseToMeElement = document.querySelector('.close-to-me');

  mapboxgl.accessToken = APP.env.MAPBOX_API_KEY;

  // Geooder require a map.
  const map = new mapboxgl.Map({
    container: 'map-wrap',
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [10.75, 59.91],
    zoom: 9,
    interactive: false,
  });

  map.on('load', () => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: 'no',
      placeholder: 'Søk på lokasjon',
      types: 'place',
      language: 'nb',
    });

    document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

    geocoder.on('result', function (event) {
      const resultCoord = event.result.center;
      const resultstring = resultCoord.toString();

      let url = `/lokasjoner?coords=${resultstring}`;

      location.assign(url);
    });

    geocoder.on('error', function (error) {
      console.error('error: ', error);
    });
  });

  findCloseToMeElement.addEventListener('click', () => {
    findCloseToMeElement.disabled = true;

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      findCloseToMeElement.disabled = false;
      findCloseToMeElement.childNodes[3].innerHTML = 'Nær meg';

      const coords = [longitude, latitude];
      const resultstring = coords.toString();

      let url = `/lokasjoner?coords=${resultstring}`;

      location.assign(url);
    }

    function error() {
      findCloseToMeElement.childNodes[3].innerHTML = 'Feil oppsto';
      console.log('Unable to retrieve your location');
    }

    if (!navigator.geolocation) {
      console.log('No support');
    } else {
      findCloseToMeElement.childNodes[3].innerHTML = 'Leter…';
      navigator.geolocation.getCurrentPosition(success, error);
    }
  });
};
