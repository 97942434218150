import 'core-js/stable';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';

import './app.scss';
import './print.scss';

// Components
import { menu } from './js/menu';
import { handleArticleSlider } from './js/articleSlider';
import { handleHeroSlider } from './js/heroSlider';
import { handleLocationSlider } from './js/locationSlider';
import { map } from './js/map/map';
import { handleFilterOptions, handleSearchBar } from './js/filter';
import { handleArticleCategorySlider } from './js/articleCategorySwiper';
import { accordion } from './js/accordion';
import { handleGallery } from './js/gallery';
import { stickyMenu } from './js/stickyMenu';
import { contactForm } from './js/contactForm';
import { handleFormModal } from './js/handleFormModal';
import { handleProductModal } from './js/handleProductModal';
import { handleMapLocationsSlider } from './js/mapLocationsSlider';
import { frontpageSearch } from './js/map/frontpageSearch';
// import { projectPicker } from './js/projectPicker';
import { staticMap } from './js/staticMap';
import { loadYouTubeScript } from './js/youTubeControl';
import { contactFormAutocomplete } from './js/contactFormAutocomplete';
import {initCheckout} from "./js/checkout";
import {initMyPage} from "./js/my-page";

const init = () => {
  // Mapbox
  const mapElement = document.querySelectorAll('.mapbox-container');
  if (mapElement) {
    mapElement.forEach((element) => {
      map(element);
    });
  }

  menu();

  // search bars
  const searchInputWrappers = document.querySelectorAll(
    '.search-input-wrapper'
  );
  if (searchInputWrappers.length) {
    searchInputWrappers.forEach((element) => {
      handleSearchBar(element);
    });
  }

  // article category slider
  const articleCategorySwiper = document.querySelector(
    '.article-category-swiper'
  );
  if (articleCategorySwiper) {
    handleArticleCategorySlider(articleCategorySwiper);
  }

  // location filter
  const locationFilters = document.querySelectorAll('.location-filter');
  if (locationFilters.length) {
    locationFilters.forEach((element) => {
      handleFilterOptions(element);
    });
  }

  // hero sliders
  const heroSliders = document.querySelectorAll('.hero-swiper');
  if (heroSliders.length) {
    heroSliders.forEach((slider) => {
      handleHeroSlider(slider);
    });
  }

  // article sliders
  const articleSliders = document.querySelectorAll('.article-swiper');
  if (articleSliders.length) {
    articleSliders.forEach((slider) => {
      handleArticleSlider(slider);
    });
  }

  //locationSlider
  const locationSliders = document.querySelectorAll('.location-swiper');
  if (locationSliders.length) {
    locationSliders.forEach((slider) => {
      handleLocationSlider(slider);
    });
  }

  // Accordions
  const accordions = document.querySelectorAll('.accordion');
  if (accordions.length) {
    accordions.forEach((element) => {
      accordion(element);
    });
  }

  // contact form modals
  const modals = document.querySelectorAll('.contact-form-modal');
  if (modals.length) {
    modals.forEach((modal) => {
      handleFormModal(modal);
    });
  }

  // contact form modals
  const productModals = document.querySelectorAll('.product-form-modal');
  if (productModals.length) {
    productModals.forEach((modal) => {
      handleProductModal(modal);
    });
  }

  // Gallery
  const galleries = document.querySelectorAll('.gallery-component');
  if (galleries.length) {
    galleries.forEach((gallery) => {
      handleGallery(gallery);
    });

    // loadYouTubeScript();
  }

  const stickyMenus = document.querySelectorAll('.sticky-menu');
  if (stickyMenus.length) {
    stickyMenus.forEach((menu) => {
      stickyMenu(menu);
    });
  }

  // Contact form
  const contactForms = document.querySelectorAll('.contact-form');
  if (contactForms.length) {
    contactForms.forEach((element) => {
      contactForm(element);
    });
  }

  const root = document.documentElement;
  const contactUsButtons = document.querySelectorAll('.contact-us-button');
  const contactUsOppButtons = document.querySelectorAll('.contact-us-opp-button');
  const contactFormModal = document.querySelector('.contact-form-modal');

  const openContactUsModal = () => {
    root.style.overflow = 'hidden';
    contactFormModal.classList.add('active');
    // open form modal
  };
  const openContactUsOppModal = (event) => {
    const name = event.target.getAttribute('data-name');
    const email = event.target.getAttribute('data-email');
    const phone = event.target.getAttribute('data-mobilephone');
    const company = event.target.getAttribute('data-companyname');
    const oppId = event.target.getAttribute('data-oppid');
    const contactModalContainer = document.getElementById('kontakt-opp');
    if (contactModalContainer) {
      const nameInput = contactModalContainer.querySelector('#name');
      const emailInput = contactModalContainer.querySelector('#email');
      const phoneInput = contactModalContainer.querySelector('#phone');
      const companyInput = contactModalContainer.querySelector('#company');
      if (nameInput) {
        nameInput.value = name;
      }
      if (emailInput) {
        emailInput.value = email;
      }
      if (phoneInput) {
        phoneInput.value = phone;
      }
      if (companyInput) {
        companyInput.value = company;
      }
    }
    const contactForm = document.querySelector('.contact-form');
    contactForm.setAttribute('data-opp-id', oppId);
    root.style.overflow = 'hidden';
    contactFormModal.classList.add('active');
    // open form modal
  };

  contactUsButtons.forEach((button) => {
    button.addEventListener('click', openContactUsModal);
  });

  contactUsOppButtons.forEach((button) => {
    button.addEventListener('click', openContactUsOppModal);
  });

  // Timeline
  const timelines = document.querySelectorAll('.timeline');
  if (timelines.length) {
    timelines.forEach((element) => {
      const moreTrigger = element.querySelector('.toggle-more');
      if (moreTrigger) {
        moreTrigger.addEventListener('click', (e) => {
          e.preventDefault();
          element.classList.toggle('more');
        });
      }
    });
  }

  // Location map cards slider
  const mapLocationsSlider = document.querySelector('.map-locations-slider');
  if (mapLocationsSlider) {
    handleMapLocationsSlider(mapLocationsSlider);
  }

  const fpSearchOuter = document.querySelector('.front-page-search-wrapper');
  if (fpSearchOuter) {
    frontpageSearch();
  }

  // Start smooth scroll polyfill
  smoothscroll.polyfill();

  // Project picker
  const projectPickerEl = document.querySelector('.project-picker');
  if (projectPickerEl) {
    // projectPicker(projectPickerEl);
  }

  // Static map
  const staticMapEl = document.querySelector('.static-map-wrapper');
  if (staticMapEl) {
    staticMap(staticMapEl);
  }

  const contactFormPlaceSearchEl = document.querySelector(
    '.autocomplete-place-search'
  );
  if (contactFormPlaceSearchEl) {
    contactFormAutocomplete();
  }

  initCheckout();
  initMyPage();
};

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

if (document.querySelector('html').classList.contains('js')) {
  init();
}
