import debounce from 'just-debounce';
import { findCloseToMe } from './findCloseToMe';

export const onMapMove = (map, features, markersArray) => {
  const showAll = document.querySelector('.map-search-show-all');
  const amount = document.querySelector('.map-search-amount');
  const allLocations = document.querySelectorAll('.location-card');

  // Keep track of locations in view
  let locationsInView = [];
  let currentLocations = [];

  // Filters
  const showSold = document.querySelector('#show-sold');
  const categoryFilters = [...document.querySelectorAll('.show-category-toggle input[type="checkbox"]')];

  initialise(map);

  const onMoveEnd = debounce(() => filterProperties(), 500, true);
  map.on('moveend', onMoveEnd);

  const updatePropertyList = (filtered) => {
    currentLocations = [];

    allLocations.forEach((location) => {
      location.style.display = 'none';
    });

    const showCategories = categoryFilters.filter(input => input.checked).map(input => input.id.match(/show-category-(.*)$/)[1]);
    filtered.forEach((location) => {
      if (location.dataset.id === 'no-id') {
        location.style.display = 'none';
        return;
      }

      let showLocation = true;
      if (! showSold.checked && location.dataset.state !== 'for_sale' && location.dataset.state !== 'coming_for_sale') {
        showLocation = false;
      }

      if (location.dataset.type && ! showCategories.includes(location.dataset.type)) {
        showLocation = false;
      }

      if (showLocation) {
        location.style.display = 'block';
        currentLocations.push(location);
      }
    });

    // update markers visibility
    allLocations.forEach(location => {
      let markerShouldBeVisible = true;
      const m = markersArray.find(marker => '' + marker.getElement().dataset.id === '' + location.dataset.id);
      if (m) {
        if (! showSold.checked && location.dataset.state !== 'for_sale' && location.dataset.state !== 'coming_for_sale') {
          markerShouldBeVisible = false;
        }
        if (location.dataset.type && ! showCategories.includes(location.dataset.type) && ! m.getElement().dataset.twinId) {
          markerShouldBeVisible = false;
        }
        m.getElement().classList.add(markerShouldBeVisible ? 'opacity-100' : 'opacity-0');
        m.getElement().classList.remove(markerShouldBeVisible ? 'opacity-0' : 'opacity-100');
      }
    });

    amount.innerHTML = currentLocations.length;
  };

  const filterProperties = () => {
    const bounds = map.getBounds();
    const inView = features.filter((feature) => {
      const coords = {
        lng: feature.geometry.coordinates[0],
        lat: feature.geometry.coordinates[1],
      };

      return bounds.contains(coords);
    });

    const inViewById = getById(inView);
    const filtered = Array.from(allLocations).filter((location) => {
      const id = location.dataset.id;
      return inViewById[id];
    });

    updateLocationsInView(filtered);
    updatePropertyList(filtered);
  };

  for (const categoryFilter of categoryFilters) {
    categoryFilter.addEventListener('change', () => updatePropertyList(locationsInView));
  }

  showSold.addEventListener('click', () => updatePropertyList(locationsInView));

  showAll.addEventListener('click', () => {
    updateLocationsInView(allLocations);
    updatePropertyList(allLocations);
  });

  const updateLocationsInView = (locations) => {
    locationsInView = [];
    locationsInView.push(...locations);
  };

  // Run once on initial load of map page
  onMoveEnd();
};

const getById = (entries = [], idKey = 'id') => {
  const byId = {};
  for (const entry of entries) {
    byId[entry.properties[idKey]] = entry;
  }

  return byId;
};

const initialise = (map) => {
  // Check for query params
  const params = new URLSearchParams(document.location.search);
  const frontpageSearch = params.get('coords');

  if (frontpageSearch) {
    const coords = frontpageSearch.split(',');
    map.flyTo({
      center: coords,
      zoom: 8,
    });
  }

  const categorySlug = params.get('kategori');

  const button = document.querySelector('.map-toggle');
  const buttonText = button.querySelectorAll('span');
  const mapOuter = document.querySelector('.map-search-map-outer');

  const filterButton = document.querySelector('.toggle-settings');
  const filterSpacer = document.querySelector('.filter-spacer');
  const filters = document.querySelector('.button-wrapper');

  const findCloseToMeElement = document.querySelector('.close-to-me');

  const expandedSize = '75vh';
  const contractedSize = '20rem';

  button.addEventListener('click', () => {
    mapOuter.classList.toggle('expanded');
    const expanded = mapOuter.classList.contains('expanded');
    mapOuter.style.height = expanded ? expandedSize : contractedSize;

    // Toggle expand/contract button text
    for (const text of buttonText) {
      text.classList.toggle('tw-hidden');
    }
  });

  filterButton.addEventListener('click', () => {
    filters.classList.toggle('open');
    const open = filters.classList.contains('open');
    const height = filters.clientHeight + 16;
    filterSpacer.style.height = open ? `${height}px` : '0px';
  });

  findCloseToMeElement.addEventListener('click', () =>
    findCloseToMe(findCloseToMeElement, map)
  );

  if (categorySlug) {
    const categoryButtons = [... document.querySelectorAll('.button-content-placer input[type="checkbox"]')];
    for (const categoryButton of categoryButtons) {
      if (categoryButton.id !== 'show-category-' + categorySlug) {
        categoryButton.checked = false;
      }
    }
  }
};
