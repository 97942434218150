export const contactFormAutocomplete = () => {
  const MAPBOX_KEY = APP.env.MAPBOX_API_KEY;
  mapboxgl.accessToken = MAPBOX_KEY;

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    countries: 'no',
    placeholder: 'Søk på stedsnavn',
    types: 'place',
    language: 'nb',
  });

  geocoder.addTo('#auto-geocoder');

  // Get the geocoder results container.
  const resultsBox = document.getElementById('geocoder-result');

  // Add geocoder result to container.
  geocoder.on('result', (e) => {
    // Clear input field when result is selected
    geocoder.clear();

    const allResults = resultsBox.dataset.geocoderResults;
    const parsedResults = JSON.parse(allResults);

    const place = e.result.text;
    let region = e.result.context[0].text;

    // If the context array has only one item, the region is Oslo.
    // This is becase Oslo is the only city that is also a county in Norway
    // Otherwise is the context is at least 2 entries long
    if (e.result.context.length === 1) {
      region = 'Oslo';
    }

    parsedResults.push([place, region]);

    renderResults(resultsBox, parsedResults);
  });
};

// Remove tag on click
const removeTag = (e) => {
  const resultsBox = document.getElementById('geocoder-result');
  const allResults = resultsBox.dataset.geocoderResults;
  const parsedResults = JSON.parse(allResults);
  const index = e.target.dataset.id;

  // Remove clicked element
  parsedResults.splice(index, 1);
  resultsBox.dataset.geocoderResults = JSON.stringify(parsedResults);

  // Render new list of tags
  renderResults(resultsBox, parsedResults);
};

// Tailwind classes
const buttonStyle =
  'tw-flex tw-items-center tw-gap-1 tw-mr-2 tw-bg-neutral-grey tw-py-2 tw-px-4 tw-tracking-wide tw-text-bodyXs tw-font-bold tw-uppercase tw-rounded-full hover:tw-brightness-105 focus:tw-outline-green';

// Render results
const renderResults = (el, array) => {
  el.dataset.geocoderResults = JSON.stringify(array);

  // Remove old tags
  el.replaceChildren();

  array.forEach((element, index) => {
    // Create elements with content
    const newButton = document.createElement('button');
    const content = document.createTextNode(element[0]);

    // Set classes and attributes
    newButton.setAttribute('class', buttonStyle);
    newButton.dataset.id = index;
    newButton.insertAdjacentHTML('afterbegin', xSvg);
    newButton.appendChild(content);
    newButton.addEventListener('click', removeTag);

    // Insert into HTML
    el.insertAdjacentElement('beforeend', newButton);
  });
};

const xSvg = `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.375 9.375L2.625 2.625" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.375 2.625L2.625 9.375" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;
