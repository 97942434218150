import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export const handleMapLocationsSlider = (element) =>
  new Swiper(element, {
    spaceBetween: 16,
    slidesPerView: 'auto',
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    }
  });
