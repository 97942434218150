import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import { onMapMove } from './onMapMove';
import { updateMarkers } from './updateMarkers';
import { getRoutes } from './getRoutes';
import { mapTranslations } from './mapTranslations';

const STYLE_URL = 'mapbox://styles/magnussmogeli/cl0kzkyzz001j14pcutuy4dzv';

export const map = (element) => {
  // Get map data from data-attributes from the twig file
  const mapId = element.dataset.mapid;
  const mapConfig = JSON.parse(element.dataset.mapconfig);
  const mapData = JSON.parse(element.dataset.mapdata);
  const mapAmenities = JSON.parse(element.dataset.amenities);

  mapboxgl.accessToken = APP.env.MAPBOX_API_KEY;

  // Create the map with the loaded configs
  const mapboxMap = new mapboxgl.Map({
    container: mapId,
    style: STYLE_URL,
    locale: mapTranslations,
    ...mapConfig,
  });

  // Add controll buttons to map
  if (mapConfig.show_controls) {
    const navigation = new mapboxgl.NavigationControl({
      showCompass: false,
    });
    const fullScreen = new mapboxgl.FullscreenControl();
    mapboxMap.addControl(fullScreen, 'top-right');
    mapboxMap.addControl(navigation, 'bottom-right');
  }

  // When map is loaded add icons
  mapboxMap.on('load', () => {

    const markersArray = [];
    if (mapAmenities) {
      updateMarkers(mapboxMap, mapId, mapAmenities, mapConfig);
      getRoutes(mapAmenities, mapData, mapboxMap);
    }

    updateMarkers(mapboxMap, mapId, mapData, mapConfig, markersArray);

    // Open location page
    mapboxMap.on('popup.click', (event) => {
      const {
        feature: {
          properties: { location_url },
        },
      } = event;

      if (location_url) {
        location.assign(location_url);
      }
    });

    // Clicking on a marker centers it
    mapboxMap.on('marker.click', (event) => {
      const mapOuter = document.querySelector('.map-search-map-outer');
      const button = document.querySelector('.map-toggle');
      if (! mapOuter.classList.contains('expanded')) {
        const e = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        button.dispatchEvent(e);
      }

      mapboxMap.flyTo({
        center: event.feature.geometry.coordinates,
      });
    });

    // Clicking on an amenity highlights the route, and card
    mapboxMap.on('amenity.click', (event) => {
      const {
        feature: {
          properties: { id },
        },
      } = event;

      const card = document.getElementById(id);

      card.click();
    });

    if (mapConfig.onMapMove) {
      onMapMove(mapboxMap, mapData.features, markersArray);
    }

    if (mapConfig.geocoder) {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxMap,
        countries: 'no',
        placeholder: 'Søk på lokasjon',
        types: 'place',
        language: 'nb',
      });

      document
        .getElementById('geocoder')
        .appendChild(geocoder.onAdd(mapboxMap));
    }
  });
};
