import mapboxgl from 'mapbox-gl';
import {closestTownIcon, popupMarkup, serviceIcon, storageIcon, storeIcon, transportIcon,} from './mapMarkup';

export const updateMarkers = (map, source, data, mapConfig, markersArray = null) => {
    const interactive = mapConfig.interactive !== false;
    const isAmenity = data.type === 'amenities';

    const {features} = data;

    for (const feature of features) {
        const {geometry, properties} = feature;

        if (!isAmenity) {
            const styleArr = [];
            if (properties && properties.icon_color) {
                if (Array.isArray(properties.icon_color)) {
                    styleArr.push('--icon-color-1: ' + properties.icon_color[0]);
                    styleArr.push('--icon-color-2: ' + properties.icon_color[1]);
                } else {
                    styleArr.push('--icon-color: ' + properties.icon_color);
                }
            }
            // Create the icon element
            const markerElement = document.createElement('div');
            markerElement.setAttribute('style', styleArr.join(';'));
            markerElement.insertAdjacentHTML('afterbegin', storageIcon);
            markerElement.classList.add('marker', interactive ? 'static' : '');
            if (properties.marker_classes) {
                markerElement.classList.add(...properties.marker_classes);
            }
            markerElement.dataset.id = properties.id;
            if (properties.twin_id) {
                markerElement.dataset.twinId = properties.twin_id;
                markerElement.classList.add('twins');
                markerElement.insertAdjacentHTML('afterbegin', storageIcon);
            }

            // Add icons on map
            const marker = new mapboxgl.Marker({
                element: markerElement,
            })
                .setLngLat(geometry.coordinates)
                .addTo(map);
            if (markersArray) {
                markersArray.push(marker);
            }

            // Add popups if map is interactive
            if (interactive) {
                // Create event listener on markers that fires off custom event if clicked
                markerElement.addEventListener('click', () => {
                    map.fire('marker.click', {markerElement, feature});
                });

                const available = properties.available;
                const image_url = properties.image_url;
                const sizes = properties.sizes;
                const title = properties.title;

                // Create the popup element
                const finalPopupElement = document.createElement('div');
                finalPopupElement.classList.add('mapboxgl-popup-inner-wrapper', 'tw-flex', 'tw-flex-col', 'tw-gap-4');

                const popUpElement = document.createElement('div');
                popUpElement.className = 'mapboxgl-popup-inner';
                popUpElement.insertAdjacentHTML(
                    'afterbegin',
                    popupMarkup(available, image_url, sizes, title, properties.category_label)
                );
                finalPopupElement.appendChild(popUpElement);

                // twin popup element
                const twinPopupElement = document.createElement('div');
                let twinFeature;
                if (properties.twin_id) {
                    twinPopupElement.className = 'mapboxgl-popup-inner';
                    twinFeature = features.find(f => f.properties.id === properties.twin_id);
                    if (twinFeature) {
                        const twinProperties = JSON.parse(JSON.stringify(twinFeature.properties));
                        twinPopupElement.insertAdjacentHTML(
                            'afterbegin',
                            popupMarkup(twinProperties.available, twinProperties.image_url, twinProperties.sizes, twinProperties.title, twinProperties.category_label)
                        );
                        finalPopupElement.appendChild(twinPopupElement);
                    }
                    finalPopupElement.classList.add('has-twin')
                }

                const popup = new mapboxgl.Popup({
                    offset: 18,
                    maxWidth: 'none',
                    closeButton: false,
                    className: 'marker-popup',
                    anchor: 'bottom',
                    focusAfterOpen: false,
                }).setDOMContent(finalPopupElement);

                // Add custom click event when popup is clicked
                popUpElement.addEventListener('click', () => {
                    map.fire('popup.click', {popup, feature});
                });
                if (twinFeature) {
                    twinPopupElement.addEventListener('click', () => {
                        map.fire('popup.click', {popup, feature: twinFeature});
                    });
                }

                // Toggle active state on clicked marker
                popup.on('open', () => {
                    markerElement.classList.add('active');
                });

                // Toggle active state on clicked marker
                popup.on('close', () => {
                    markerElement.classList.remove('active');
                });

                // Add the popup element to each marker
                marker.setPopup(popup);
            }
        }

        if (isAmenity) {
            const {
                properties: {category, id},
            } = feature;

            // Create the icon element
            const markerElement = document.createElement('div');

            let icon = findAmenityIcon(category);

            markerElement.insertAdjacentHTML('afterbegin', icon);
            markerElement.className = `marker-amenity ${id}`;

            // Add icons on map
            const marker = new mapboxgl.Marker({
                element: markerElement,
            })
                .setLngLat(geometry.coordinates)
                .addTo(map);

            // Add popups if map is interactive
            if (interactive) {
                // Create event listener on markers that fires off custom event if clicked
                markerElement.addEventListener('click', () => {
                    map.fire('amenity.click', {markerElement, feature});
                });
            }
        }
    }
};

const findAmenityIcon = (category) => {
    switch (category) {
        case 'store':
            return storeIcon;

        case 'closestTown':
            return closestTownIcon;

        case 'service':
            return serviceIcon;

        case 'transport':
            return transportIcon;

        default:
            return storeIcon;
    }
};
