export const loadYouTubeScript = () => {
  const allScripts = document.querySelectorAll('script');
  const hasScript = Array.from(allScripts).some(
    (scripts) => scripts.src === 'https://www.youtube.com/iframe_api'
  );

  if (hasScript) {
    return;
  }

  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};
