import { loadYouTubeScript } from './youTubeControl';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export const handleHeroSlider = (sliderElm) => {
  const sliderIdentifier = sliderElm.dataset.slider;
  const videoBool = sliderElm.dataset.hasvideo;
  const hasVideo = videoBool === 'true' ? true : false;

  window.onYouTubeIframeAPIReady = () => {
    let playerEle = document.querySelectorAll('[iframe-video]');
    let buttons = document.querySelectorAll('.swiper-pagination-bullet');
    console.log('playerEle', playerEle);

    playerEle.forEach((item) => {
      let itemEle;
      const elementId = item.dataset.id;

      itemEle = new YT.Player(elementId, {});

      buttons.forEach((item) => {
        item.addEventListener('click', function () {
          itemEle.pauseVideo();
        });
      });
    });
  };

  if (hasVideo) {
    loadYouTubeScript();
  }

  return new Swiper(`#${sliderIdentifier}`, {
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.hero-swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return `<span class="tw-bg-white ${className}"></span>`;
      },
    },
    spaceBetween: 0,
    speed: 2000,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    // Has to be false for video, otherwise Swiper injects duplicate slides
    // https://swiperjs.com/swiper-api#param-loop
    loop: hasVideo ? false : true,
    freeMode: false,
    cssMode: false,
    lazy: true,
  });
};
