/**
 * Accordion component consiting of collapsible sub components.
 * @param {object} element
 */
export const accordion = (element) => {
  function handleMoreClick(e) {
    e.preventDefault();
    element.classList.toggle('more');
  }

  const toggleMore = element.querySelector('.toggle-more')
  if (toggleMore) toggleMore.addEventListener('click', handleMoreClick);

  // Collapsibles
  const collapsibles = element.querySelectorAll('.collapsible');

  if (collapsibles.length) {
    collapsibles.forEach((element) => {
      collapsible(element);
    });
  }
};

/**
 * Collapsible component for toggling content.
 * Can be used as stand-alone component.
 * @param {object} element
 */
export const collapsible = (element) => {
  function handleCollapsibleTrigger(e) {
    if (!element.classList.contains('collapsed')) {
      element.classList.remove('collapsed');
    }

    element.classList.toggle('collapsed');
  }

  element
    .querySelector('.collapsible-trigger')
    .addEventListener('click', handleCollapsibleTrigger);
};
