import mapboxgl from 'mapbox-gl';

export const getRoutes = (mapAmenities, mapData, map) => {
  const { features } = mapAmenities;

  const startCoords = mapData.features[0].geometry.coordinates.toString();
  const MAPBOX_KEY = APP.env.MAPBOX_API_KEY;

  const setBounds = () => {
    const coords = features.map((feature) => {
      return feature.geometry.coordinates;
    });

    const locationCoords = mapData.features[0].geometry.coordinates;

    // Make location coords into a number
    const numberify = locationCoords.map((location) => parseFloat(location));

    // Add location to coord array
    coords.push(numberify);

    let max = [0, 0];
    let min = [Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER];

    for (const coord of coords) {
      const [x, y] = coord;
      if (x > max[0]) {
        max[0] = x;
      }
      if (y > max[1]) {
        max[1] = y;
      }
      if (x < min[0]) {
        min[0] = x;
      }
      if (y < min[1]) {
        min[1] = y;
      }
    }

    const bounds = new mapboxgl.LngLatBounds([max, min]);

    map.fitBounds(bounds, {
      maxZoom: 12,
      padding: { top: 25, bottom: 25, left: 25, right: 25 },
    });
  };

  const getRoute = async (feature, index) => {
    const endCoords = feature.geometry.coordinates.toString();
    const URL = `https://api.mapbox.com/directions/v5/mapbox/driving/${startCoords};${endCoords}?geometries=geojson&access_token=${MAPBOX_KEY}`;

    try {
      const response = await fetch(URL, { method: 'GET' });
      if (!response.ok) {
        console.log('response', response);
        throw new Error(response.statusText);
      }

      const body = await response.json();
      const route = body.routes[0].geometry.coordinates;
      const geojson = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: route,
        },
      };

      const active = '#007473';
      const idle = '#B5B5B5';

      const layerId = `route${index}`;

      map.addLayer({
        id: layerId,
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': idle,
          'line-width': 3,
          'line-opacity': 1,
        },
      });
      const id = feature.properties.id;
      const card = document.getElementById(id);
      const cardDuration = card.querySelector('.location-card-duration');

      const durationResponse = body.routes[0].duration;

      const isMinutes = durationResponse > 60;
      const seconds = Math.floor(durationResponse);
      const minutes = Math.floor(durationResponse / 60);

      const icons = document.querySelectorAll('.marker-amenity');

      const text = isMinutes ? `${minutes} min` : `${seconds} sek`;
      cardDuration.innerHTML = text;

      card.addEventListener('click', () => {
        // Unselect all
        const parent = card.parentNode;
        Array.from(parent.children).forEach((child, index) => {
          child.classList.remove('active');
          map.setPaintProperty(`route${index}`, 'line-color', idle);
          icons[index].classList.remove('active');
        });

        // Find current icon
        const currentIcon = Array.from(icons).filter((icon) =>
          icon.classList.contains(id)
        );

        // Set clicked route to active
        map.setPaintProperty(layerId, 'line-color', active);
        card.classList.add('active');
        currentIcon[0].classList.add('active');
        map.moveLayer(layerId);
      });
    } catch (error) {
      console.error('error: ', error);
    }
  };

  setBounds();

  features.forEach((feature, index) => {
    getRoute(feature, index);
  });
};
