export const findCloseToMe = (element, map) => {
  element.disabled = true;

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    map.flyTo({ center: [longitude, latitude], zoom: 10 });
    element.disabled = false;
    element.childNodes[3].innerHTML = 'Nær meg';
  }

  function error() {
    element.childNodes[3].innerHTML = 'Feil oppsto';
    console.log('Unable to retrieve your location');
  }

  if (!navigator.geolocation) {
    console.log('No support');
  } else {
    element.childNodes[3].innerHTML = 'Leter…';
    navigator.geolocation.getCurrentPosition(success, error);
  }
};
