import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export const handleArticleSlider = (sliderElm) => {
  const sliderIdentifier = sliderElm.dataset.slider;

  return new Swiper(`#${sliderIdentifier}`, {
    navigation: {
      nextEl: `.article-swiper-button-next-${sliderIdentifier}`,
      prevEl: `.article-swiper-button-prev-${sliderIdentifier}`,
    },
    slidesPerView: 'auto',
    spaceBetween: 24,
    freeMode: true,
    cssMode: true,
    mousewheel: {
      forceToAxis: true,
    },
  });
};
