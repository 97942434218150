export const menu = () => {
  // TODO: Keyboard navigation
  const headerButton = document.querySelector('.header-menu');
  const flyoutMenu = document.querySelector('.header-flyout-menu');
  const body = document.querySelector('body');
  const root = document.documentElement;

  const handleMenuClick = () => {
    flyoutMenu.classList.toggle('active');
    headerButton.classList.toggle('active');

    const menuOpen = flyoutMenu.classList.contains('active');

    if (menuOpen) {
      body.classList.add('no-scroll');
      root.style.overflow = 'hidden';
      const overlayElement = document.createElement('div');
      overlayElement.className = 'overlay-element';
      overlayElement.setAttribute('aria-hidden', 'true');
      flyoutMenu.insertAdjacentElement('beforebegin', overlayElement);

      overlayElement.addEventListener('click', () => closeMenu());
    } else {
      // In case someone closes the menu by clicking the menu button, instead of
      // clicking outside of the menu.
      closeMenu();
    }
  };

  if (headerButton) {
    headerButton.addEventListener('click', handleMenuClick);
  }

  const closeMenu = (element) => {
    flyoutMenu.classList.remove('active');
    headerButton.classList.remove('active');
    body.classList.remove('no-scroll');
    root.style.overflow = 'visible';

    const overlays = document.querySelectorAll('.overlay-element');

    for (const overlay of overlays) {
      overlay.remove();
    }

    if(element) {
      element.remove();
    }
  };
};
