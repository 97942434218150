const BLACKLIST_DOMAINS = [
  'mailinator.com',
];

export const isEmailBlacklisted = (email) => {

  const address = email.split('@').pop();

  if (BLACKLIST_DOMAINS.includes(address)) return true;

  return false;
}

export const contactForm = (element) => {
  let refererUrl = element.dataset?.pageUrl || null;
  let projectUuid = element.dataset?.projectUuid || null;
  let opportunityId = element.dataset?.oppId || null;
  let unitUuid = element.dataset?.unitUuid || null;
  let prependMessage = element.dataset?.prependMessage || '';
  const name = element.querySelector('#name');
  const company = element.querySelector('#company');
  const phone = element.querySelector('#phone');
  const email = element.querySelector('#email');
  const message = element.querySelector('#message');

  const geocoderResult = element.querySelector('#geocoder-result');

  async function handleSubmit(e) {
    e.preventDefault();

    const locationHints =
      (geocoderResult && geocoderResult.dataset?.geocoderResults) || false;
    const parsedHints = JSON.parse(locationHints);

    refererUrl = e.target?.dataset?.pageUrl || null;
    opportunityId = e.target?.dataset?.oppId || null;
    projectUuid = e.target?.dataset?.projectUuid || null;
    unitUuid = e.target?.dataset?.unitUuid || null;
    prependMessage = e.target?.dataset?.prependMessage || '';
    const csrf = e.target?.querySelector('[name="CRAFT_CSRF_TOKEN"]').value || '';
    const actionUrl = e.target?.querySelector('[name="action"]').value || '';
    const locationId = e.target?.dataset?.locationId || null;
    const entryId = e.target?.dataset?.entryId || null;
    const dynamicsSectionId = e.target?.dataset?.dynamicsSectionId || null;;

    const body = {
      referer: refererUrl,
      fullName: name?.value,
      email: email?.value,
      phoneNumber: phone.value,
      opportunityId: opportunityId,
      company: company?.value,
      message: prependMessage + message?.value,
      projectExternalId: projectUuid,
      unitExternalId: unitUuid,
      locationId,
      entryId,
      dynamicsSectionId,
      CRAFT_CSRF_TOKEN: csrf,
    };

    if (geocoderResult) {
      body.locations = parsedHints;
    }

    if (email?.value && isEmailBlacklisted(email?.value)) {
      return;
    }

    e.target.querySelector('button').disabled = 'disabled';
    e.target.querySelector('.loading').classList.remove('tw-hidden');

    try {
      const response = await fetch(actionUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        referrer: '',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(body),
      });

      e.target.querySelector('button').removeAttribute('disabled');
      e.target.querySelector('.loading').classList.add('tw-hidden');

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      await response.json();

      element.classList.add('submitted');
      name.value = '';
      company.value = '';
      phone.value = '';
      email.value = '';
      message.value = '';
    } catch (error) {
      console.error(error);
    }
  }

  element.addEventListener('submit', handleSubmit);
};
