import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export const handleArticleCategorySlider = (sliderElm) => {
  const sliderIdentifier = sliderElm.dataset.slider;

  return new Swiper(`.${sliderIdentifier}`, {
    slidesPerView: 'auto',
    spaceBetween: 8,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
  });
};
