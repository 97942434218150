export const popupMarkup = (available, image, size, title, label) => {
  return `
    ${image ? `
      <picture>
        <img src="${image}" alt="${title ? title : ''}"/>
      </picture>
    ` : ''}
    <div class="tw-flex tw-flex-col tw-justify-center tw-grow">
      ${label ? `
        <div class="tw-mb-1">${label}</div>
      ` : ''}
      ${title ? `
        <h3 class="tw-text-heading5-m md:tw-text-heading5 tw-font-bold tw-mb-3">
          ${title}
        </h3>
      ` : ''}
      <div class="tw-flex tw-justify-center">
        ${available ? `
          <p class="tw-mr-[20px]">
            ${availableIcon}${available}
          </p>
        `: ''}
        ${size ? ` <p>${sizeIcon}${size}</p>` : ''}
      </div>
    </div>
  `;
};

export const storageIcon = `
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="map-icon-background" d="M17.4064 29.6247L2.80151 23.4252C2.24566 23.1899 1.74167 22.8473 1.31837 22.4171C0.895066 21.9869 0.560761 21.4774 0.334574 20.9178C0.108386 20.3583 -0.00524603 19.7597 0.000185941 19.1562C0.00561791 18.5527 0.130004 17.9562 0.366227 17.4008L6.55986 2.79635C6.79596 2.24051 7.13941 1.7367 7.57055 1.31379C8.00169 0.890872 8.51205 0.557161 9.07238 0.331765C9.63271 0.10637 10.232 -0.00627908 10.836 0.000270003C11.4399 0.00681908 12.0366 0.132438 12.5919 0.369931L27.1985 6.56946C27.7543 6.80475 28.2583 7.14732 28.6816 7.57755C29.1049 8.00779 29.4392 8.51725 29.6654 9.07679C29.8916 9.63634 30.0052 10.235 29.9998 10.8385C29.9944 11.442 29.87 12.0385 29.6338 12.5939L23.4333 27.1983C23.198 27.7543 22.8553 28.2584 22.4249 28.6817C21.9945 29.1051 21.4848 29.4395 20.925 29.6656C20.3651 29.8918 19.7662 30.0054 19.1624 29.9998C18.5587 29.9942 17.9619 29.8697 17.4064 29.6332"/>
    <path class="map-icon-letter" d="M7.30127 20.7963H14.721V18.3512H10.109V6.76318H7.30127V20.7963Z"/>
    <path class="map-icon-letter" d="M19.9949 20.9954C22.4625 20.9954 23.7243 19.6725 23.7243 17.6287C23.7243 15.5849 22.2822 15.0016 20.8333 14.6412C19.5902 14.3402 18.847 14.1396 18.847 13.3982C18.847 12.7555 19.3487 12.4953 19.8895 12.4953C20.6326 12.4953 20.9524 12.8354 20.9524 13.3982V13.6328H23.5033V13.3931C23.5033 11.5482 22.0799 10.3647 19.8742 10.3647C17.4678 10.3647 16.2246 11.6281 16.2246 13.5733C16.2246 15.7379 17.6872 16.3993 19.131 16.7207C20.2347 16.9622 21.0357 17.1424 21.0357 17.8634C21.0357 18.5843 20.5357 18.8666 19.8742 18.8666C19.0919 18.8666 18.6701 18.4653 18.6701 17.7834V17.4621H16.0835V17.763C16.0835 19.7474 17.5069 20.9937 19.9949 20.9937"/>
  </svg>`;

export const sizeIcon = `
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="4" width="17" height="17" stroke="currentColor"/>
    <line x1="12.7071" y1="13.25" x2="20.25" y2="20.7929" stroke="currentColor" stroke-linecap="square"/>
    <line x1="12.7071" y1="8.75" x2="20.25" y2="16.2929" stroke="currentColor" stroke-linecap="square"/>
    <line x1="12.7071" y1="4.25" x2="20.25" y2="11.7929" stroke="currentColor" stroke-linecap="square"/>
    <line x1="17.2071" y1="4.25" x2="20.25" y2="7.29289" stroke="currentColor" stroke-linecap="square"/>
    <line x1="12.7071" y1="17.75" x2="15.75" y2="20.7929" stroke="currentColor" stroke-linecap="round"/>
    <line x1="12.5" y1="4" x2="12.5" y2="21" stroke="currentColor" stroke-linecap="round"/>
  </svg>`;

export const availableIcon = `
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="3.25" width="17" height="18.5" stroke="currentColor"/>
    <rect x="6.5" y="9.25" width="11" height="12.5" stroke="currentColor"/>
    <path d="M5.25 17.375H3.75" stroke="currentColor"/>
    <path d="M20.25 17.375H18.75" stroke="currentColor"/>
  </svg>`;

export const storeIcon = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 23H8.72727L5.2402 3.82112C5.19831 3.5907 5.07688 3.3823 4.89708 3.23225C4.71728 3.08219 4.49052 3 4.25633 3H2" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M10 28C11.3807 28 12.5 26.8807 12.5 25.5C12.5 24.1193 11.3807 23 10 23C8.61929 23 7.5 24.1193 7.5 25.5C7.5 26.8807 8.61929 28 10 28Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M23 28C24.3807 28 25.5 26.8807 25.5 25.5C25.5 24.1193 24.3807 23 23 23C21.6193 23 20.5 24.1193 20.5 25.5C20.5 26.8807 21.6193 28 23 28Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.81818 18H23.5127C23.981 18 24.4346 17.8356 24.7942 17.5355C25.1538 17.2354 25.3966 16.8186 25.4804 16.3578L27 8H6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
`
export const transportIcon = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 16H26.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.4 26.3C12.5657 26.0791 12.5209 25.7657 12.3 25.6C12.0791 25.4343 11.7657 25.4791 11.6 25.7L12.4 26.3ZM8.6 29.7L8.3 30.1L9.1 30.7L9.4 30.3L8.6 29.7ZM11.6 25.7L8.6 29.7L9.4 30.3L12.4 26.3L11.6 25.7Z" fill="currentColor" />
    <path d="M20.4 25.7C20.2343 25.4791 19.9209 25.4343 19.7 25.6C19.4791 25.7657 19.4343 26.0791 19.6 26.3L20.4 25.7ZM22.6 30.3L22.9 30.7L23.7 30.1L23.4 29.7L22.6 30.3ZM19.6 26.3L22.6 30.3L23.4 29.7L20.4 25.7L19.6 26.3Z" fill="currentColor" />
    <path d="M23.5 4H8.5C6.84315 4 5.5 5.34315 5.5 7V23C5.5 24.6569 6.84315 26 8.5 26H23.5C25.1569 26 26.5 24.6569 26.5 23V7C26.5 5.34315 25.1569 4 23.5 4Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5 22.5C11.0523 22.5 11.5 22.0523 11.5 21.5C11.5 20.9477 11.0523 20.5 10.5 20.5C9.94772 20.5 9.5 20.9477 9.5 21.5C9.5 22.0523 9.94772 22.5 10.5 22.5Z" fill="currentColor" />
    <path d="M21.5 22.5C22.0523 22.5 22.5 22.0523 22.5 21.5C22.5 20.9477 22.0523 20.5 21.5 20.5C20.9477 20.5 20.5 20.9477 20.5 21.5C20.5 22.0523 20.9477 22.5 21.5 22.5Z" fill="currentColor" />
  </svg>
`
export const closestTownIcon = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.40696 12.8937C4.95035 10.858 6.02253 9.00228 7.51472 7.51477" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M7.51331 24.4867C6.02204 22.9983 4.95101 21.1419 4.40889 19.1058" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M19.1063 27.593C17.0717 28.1403 14.9285 28.1396 12.8942 27.5911" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M27.593 19.1063C27.0497 21.142 25.9775 22.9978 24.4853 24.4853" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M24.4867 7.51331C25.978 9.00174 27.049 10.8581 27.5911 12.8942" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
    <path d="M12.8937 4.4069C14.9283 3.85964 17.0715 3.86031 19.1058 4.40883" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" />
  </svg>
`
export const serviceIcon = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.66531 5.76954L6.9531 8.53909L1.52868 14.0782C1.36106 14.2493 1.22811 14.4525 1.1374 14.6761C1.04669 14.8998 1 15.1394 1 15.3815C1 15.6235 1.04669 15.8632 1.1374 16.0868C1.22811 16.3105 1.36106 16.5137 1.52868 16.6848L11.1017 26.4601C11.4402 26.8058 11.8993 27 12.378 27C12.8567 27 13.3158 26.8058 13.6543 26.4601L24.5032 15.382L29 16.0868L23.5 4.5L12.3775 3L9.66531 5.76954ZM9.66531 5.76954C8.94354 5.1797 7.13246 3.86754 6.5 4.5C5 6 5 6 4 7C3.5 7.5 3.5 7.5 1.5 10C1.11803 10.4775 1.71221 12.2282 2.55689 13.0282" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26 25.5C26 23.0007 28.5 20.5 28.5 20.5C28.5 20.5 31 23.0007 31 25.5C31 26.163 30.7366 26.7989 30.2678 27.2678C29.7989 27.7366 29.163 28 28.5 28C27.837 28 27.2011 27.7366 26.7322 27.2678C26.2634 26.7989 26 26.163 26 25.5V25.5Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`
