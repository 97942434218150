import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// const galleries = [];

// window.onYouTubeIframeAPIReady = () => {
//   galleries.forEach((gallery) => {
//     let playerEle = gallery.element.querySelectorAll('[iframe-video]');
//     console.log('playerEle', playerEle);

//     playerEle.forEach((iframe) => {
//       let player;
//       const iframeId = iframe.id;

//       // This should work, but for some reason it doesn't work.
//       // It works in /src/js/heroSlider.js, and the setup is pretty much the
//       // same, with no real difference.

//       player = new YT.Player(iframeId, {
//         events: {
//           onReady: onPlayerReady,
//         },
//       });

//       // Player object is missing info.
//       // In /src/js/heroSlider.js the object is much more complete. I am not
//       // able to spot the difference, nor the reason for why it doesn't work.

//       function onPlayerReady() {
//         // Should run, but never does
//         console.log('Player ready');
//       }

//       // document.addEventListener('click', function () {
//       //   console.log('clicking to pause');
//       //   console.log('player', player);
//       //   player.pauseVideo();
//       // });
//     });
//   });
// };

export const handleGallery = (element) => {
  const galleryId = element.dataset.id;
  // const hasVideoBool = element.dataset.hasvideo;
  // const hasVideo = hasVideoBool === 'true' ? true : false;
  const root = document.documentElement;

  // const galleryObj = {
  //   element: element,
  // };

  // if (hasVideo) {
  //   galleries.push(galleryObj);
  // }

  // const pauseVideo = () => {
  //   console.log('i should pause');
  // };

  const thumbnail = new Swiper(`.thumbnail-${galleryId}`, {
    spaceBetween: 4,
    slidesPerView: 4,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 5,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 8,
      },
      1080: {
        slidesPerView: 12,
      }
    },
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });

  const swiperTop = new Swiper(`.gallery-${galleryId}`, {
    spaceBetween: 16,
    keyboard: {
      enabled: false,
    },
    thumbs: {
      swiper: thumbnail,
    },
    on: {
      slideChange: ({ activeIndex }) => {
        swiperTopLightbox.slideTo(activeIndex, false, false);
      },
      click: (slider) => {
        openLightBox();
      },
    },
  });

  const thumbnailLightBox = new Swiper(`.thumbnail-lightbox-${galleryId}`, {
    spaceBetween: 4,
    slidesPerView: 4,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 5,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 8,
      },
      1080: {
        slidesPerView: 12,
      }
    },
    freeMode: {
      enabled: true,
      sticky: true,
    },
  });

  const swiperTopLightbox = new Swiper(`.lightbox-${galleryId}`, {
    spaceBetween: 16,
    zoom: true,
    navigation: {
      nextEl: `.lightbox-next-button-${galleryId}`,
      prevEl: `.lightbox-prev-button-${galleryId}`,
    },
    thumbs: {
      swiper: thumbnailLightBox,
    },
    keyboard: {
      enabled: true,
    },
    on: {
      slideChange: ({ activeIndex }) => {
        thumbnailLightBox.slideTo(activeIndex, 300, false);

        // pauseVideo();
      },
      keyPress: (slider, keyCode) => {
        if (keyCode == 27) {
          closeLightBox();
          // pauseVideo();
        }
      },
      click: (slider) => {
        slider.zoom.toggle();
      },
    },
  });

  const lightBox = element.querySelector('.gallery-lightbox');

  const openLightBox = () => {
    if (!lightBox.classList.contains('gallery-lightbox-open')) {
      document.body.classList.add('no-scroll');
      root.style.overflow = 'hidden';
      lightBox.classList.add('gallery-lightbox-open');
    }
  };
  const closeLightBox = () => {
    if (lightBox.classList.contains('gallery-lightbox-open')) {
      document.body.classList.remove('no-scroll');
      root.style.overflow = 'visible';
      lightBox.classList.remove('gallery-lightbox-open');
    }
  };

  const openLightBoxButton = element.querySelector('.open-lightbox');
  const closeLightBoxButton = element.querySelector('.close-lightbox');

  if (openLightBoxButton) {
    openLightBoxButton.addEventListener('click', openLightBox);
  }
  if (closeLightBoxButton) {
    closeLightBoxButton.addEventListener('click', closeLightBox);
  }
};
