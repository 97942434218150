export const handleSearchBar = (element) => {
  const searchInput = element.querySelector('.search-input');
  const closeButton = element.querySelector('.close-button');
  const mapPinButton = element.querySelector('.map-pin-button');
  const searchInputLocations = element.querySelector('.search-input-locations');
  const searchTemplate = element.querySelector('[data-search-template]');

  // locationListData
  const searchLocationList = JSON.parse(searchInputLocations.dataset.locations);

  const handleLocationOptionClick = (e) => {
    searchInput.value = e.target.textContent;
    searchInputLocations.classList.toggle('tw-hidden');
  };

  // loop through search list and return new object with data and element inside. add event listener for each element.
  const locations = searchLocationList.map((loc) => {
    const location = searchTemplate.content.cloneNode(true).children[0];
    location.textContent = loc.location;
    searchInputLocations.append(location);

    location.addEventListener('click', handleLocationOptionClick);
    return { location: loc.location, element: location };
  });

  const clearFilters = () => {
    closeButton.classList.add('tw-hidden');
    mapPinButton.classList.remove('tw-hidden');

    searchInput.value = '';
    if (!searchInputLocations.classList.contains('tw-hidden')) {
      searchInputLocations.classList.add('tw-hidden');
    }
  };

  const handleSearchInput = (e) => {
    const value = e.target.value.toLowerCase();
    let resultsVisible = false;

    if (value) {
      closeButton.classList.remove('tw-hidden');
      mapPinButton.classList.add('tw-hidden');
    } else {
      closeButton.classList.add('tw-hidden');
      mapPinButton.classList.remove('tw-hidden');
    }

    // check if element location includes input value and toggle class.
    locations.forEach((loc) => {
      const isVisible = value && loc.location.toLowerCase().includes(value);

      if (isVisible) resultsVisible = true;
      loc.element.classList.toggle('tw-hidden', !isVisible);
    });

    searchInputLocations.classList.toggle('tw-hidden', !resultsVisible);
  };

  const handleSearch = () => {
    // filter something
  };

  const handleLocation = () => {
    // filter something
  };

  searchInput.addEventListener('input', handleSearchInput);
  mapPinButton.addEventListener('click', handleLocation);
  closeButton.addEventListener('click', clearFilters);
  element
    .querySelector('.search-button')
    .addEventListener('click', handleSearch);
};


export const handleFilterOptions = (element) => {
  const filterOptions = element.querySelector('.filter-options');
  const toggleOptionButton = element.querySelector('.toggle-option-button');
  const chipSize72 = element.querySelector('.chip-size-72');
  const chipSize144 = element.querySelector('.chip-size-144');

  const handleToggleSold = (e) => {
    // filter something
  };

  const handleChipToggle = (e, element) => {
    // filter something
  };

  const toggleOptions = () => {
    filterOptions.classList.toggle('filter-options-visible');
    toggleOptionButton.classList.toggle('tw-bg-tertiary-active');
  };


  element
    .querySelector('.toggle-sold')
    .addEventListener('change', handleToggleSold);

  toggleOptionButton.addEventListener('click', toggleOptions);
  chipSize72.addEventListener('change', (e) => handleChipToggle(e, chipSize72));
  chipSize144.addEventListener('change', (e) =>
    handleChipToggle(e, chipSize144)
  );
};
