export const staticMap = (element) => {
  const MAPBOX_KEY = APP.env.MAPBOX_API_KEY;
  const cards = element.querySelectorAll('.map-location-card');
  const startCoords = element.dataset.homeCoord;

  const findRoute = async (card) => {
    const endCoords = card.dataset.endCoord;
    const URL = `https://api.mapbox.com/directions/v5/mapbox/driving/${startCoords};${endCoords}?geometries=geojson&access_token=${MAPBOX_KEY}`;

    try {
      const response = await fetch(URL, { method: 'GET' });
      if (!response.ok) {
        console.log('response', response);
        throw new Error(response.statusText);
      }

      const body = await response.json();

      const cardDuration = card.querySelector('.location-card-duration');

      const durationResponse = body.routes[0].duration;

      const isMinutes = durationResponse > 60;
      const seconds = Math.floor(durationResponse);
      const minutes = Math.floor(durationResponse / 60);

      const text = isMinutes ? `${minutes} min` : `${seconds} sek`;
      cardDuration.innerHTML = text;
    } catch (error) {
      console.error('error: ', error);
    }
  };

  cards.forEach((card) => {
    findRoute(card);
  });
};
