export const handleProductModal = (element) => {
  const root = document.documentElement;
  const formWrapper = element.querySelector('.product-form-wrapper');
  const closeButton = element.querySelector('.product-form-close');

  const closeModal = () => {
    element.classList.remove('active');
    root.style.overflow = 'visible';
  };

  element.addEventListener('click', closeModal);
  closeButton.addEventListener('click', closeModal);
  formWrapper.addEventListener('click', (e) => {
    e.stopPropagation();
  });
};
