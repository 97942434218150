import { debounce } from './debounce';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export const stickyMenu = (element) => {
  const offset = 80;
  const menuItems = document.querySelectorAll('.sticky-menu-item');
  const firstMenuItem = menuItems[0];
  const lastMenuItem = menuItems[menuItems.length - 1];
  const menuLinks = element.querySelectorAll('a');
  const menuLinksWrapper = element.querySelector('.sticky-menu-links');
  const currentClassName = 'current';
  const scrollingClassName = 'sticky-scrollingsad';
  let currentItem = null;

  const menuSwiper = element.querySelector('.sticky-menu-swiper');
  const contactUsButtons = document.querySelectorAll('.contact-us-button');
  const contactFormModal = document.querySelector('.contact-form-modal');
  const root = document.documentElement;

  const menuSlider = new Swiper(menuSwiper, {
    slidesPerView: 'auto',
    freeMode: true,
  });

  function resetClasses() {
    // Reset items
    menuItems.forEach((item) => {
      item.classList.remove(currentClassName);
    });

    // Reset links
    if (!document.body.classList.contains(scrollingClassName)) {
      menuLinks.forEach((item) => {
        item.classList.remove(currentClassName);
      });
    }

    // Remove location hash
    //updateLocationHash(' ');
  }

  function setCurrentItem(item, index) {

    menuSlider.slideTo(index, 300);
    // Don't run if we're on the same section
    if (item.classList.contains(currentClassName)) return;

    // Reset classes
    resetClasses();

    // Add current class name to items
    item.classList.add(currentClassName);

    let currenLink = menuLinks[index];

    // Don't auto-scroll if we're already scrolling
    if (!document.body.classList.contains(scrollingClassName)) {
      setCurrentMenuItem(currenLink, index);
    }

    // Update location hash
    //updateLocationHash(currenLink.href);
  }

  // const updateLocationHash = debounce((state) => {
  //   history.pushState(null, null, state);
  // }, 200);

  function setCurrentMenuItem(item) {
    item.classList.add(currentClassName);

    // Scroll to active link
    menuLinksWrapper.scroll({
      left: item.offsetLeft - menuLinksWrapper.offsetLeft,
      behavior: 'smooth',
    });
  }

  const onScroll = (e) => {
    let scrollY = window.scrollY;
    let index = null;

    menuItems.forEach((item, i) => {
      let topPos = item.offsetTop - offset;
      let botPos = item.offsetTop + item.clientHeight;

      // Check if we're within a section's bounds
      if (scrollY >= topPos && scrollY <= botPos) {
        currentItem = item;
        index = i;
      } else if (
        scrollY < firstMenuItem.offsetTop - offset ||
        scrollY > lastMenuItem.offsetTop + lastMenuItem.clientHeight
      ) {
        currentItem = null;
      }
    });

    // Update current item
    if (currentItem && index !== null) {
      setCurrentItem(currentItem, index);
    }
  };

  const onMenuClick = debounce((item) => {
    let url = item.href;
    let elementId = '#' + url.substring(url.indexOf('#') + 1);
    let toElement = document.querySelector(elementId);

    document.body.classList.add(scrollingClassName);

    menuLinks.forEach((item) => {
      item.classList.remove(currentClassName);
    });

    document.documentElement.scroll({
      top: toElement.offsetTop,
      behavior: 'smooth',
    });

    setCurrentMenuItem(item);

    setTimeout(() => {
      document.body.classList.remove(scrollingClassName);
    }, 1000);
  }, 100);

  // Handle scroll event
  document.addEventListener('scroll', onScroll);

  const openContactUsModal = () => {
    root.style.overflow = 'hidden';
    contactFormModal.classList.add('active');
    // open form modal
  };

  contactUsButtons.forEach((button) => {
    button.addEventListener('click', openContactUsModal);
  });

  // Handle click event
  menuLinks.forEach((item) => {
    item.addEventListener('click', (e) => {
      const url = e.target.href;
      if (! url.includes('#')) {
        return;
      }
      e.preventDefault();
      onMenuClick(e.target);
    });
  });
};
