export const handleFormModal = (element) => {
  const root = document.documentElement;
  const formWrapper = element.querySelector('.contact-form-wrapper');
  const closeButton = element.querySelector('.contact-form-close');
  const contactForm = element.querySelector('.contact-form');

  const closeModal = () => {
    element.classList.remove('active');
    root.style.overflow = 'visible';
    contactForm.removeAttribute('data-unit-uuid');
    contactForm.removeAttribute('data-prepend-message');
    contactForm.classList.remove('submitted');
  };

  element.addEventListener('click', closeModal);
  closeButton.addEventListener('click', closeModal);
  formWrapper.addEventListener('click', (e) => {
    e.stopPropagation();
  });
};
